import _ from 'lodash';
import { IHierarchyService } from '../../../modules/hierarchy';
import { ICustomerTransaction } from '../../../modules/services/query-service.types';
import { ICustomer } from '../customer.types';
import { isObject } from '../../../lib/utils/utils';
import { IPropertyDefinition } from '../../../lib/config-hierarchy';
import { ConfigAPI } from '../../../lib/config-api';
import {
    ConfigCustomerDetailsPropertiesSchema,
    IConfigCustomerDetailsProperties,
    IConfigCustomerDetailsProperty,
} from '../../../lib/types';
import { ICustomerAPIFactory } from '../customer.service';

export const CustomerDetailsControllerInstance = () => [
    '$q',
    '$scope',
    '$route',
    'promiseTracker',
    'Hierarchy',
    'CustomerAPI',
    function CustomerDetailsController(
        $q: angular.IQService,
        $scope: angular.IScope & {
            customer: ICustomer | undefined;
            customerExtraProperties: IConfigCustomerDetailsProperty[];
            select: (index: number) => void;
            selectPreviousTransaction: () => void;
            selectNextTransaction: () => void;
            customerDetailsPromiseTracker: angular.promisetracker.PromiseTracker;
            transactionIndex: number;
            transaction?: ICustomerTransaction;
            itemProperties: IPropertyDefinition[];
        },
        $route: angular.route.IRouteService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,

        Hierarchy: IHierarchyService,
        CustomerAPI: ICustomerAPIFactory,
    ) {
        $scope.customerDetailsPromiseTracker = promiseTracker();
        $scope.customerExtraProperties = [];
        const customerId = (() => {
            if (!$route.current) {
                return undefined;
            }

            if (isObject($route.current.params) && 'customerId' in $route.current.params) {
                const customerId = (() => {
                    if (typeof $route.current.params.customerId === 'string') return $route.current.params.customerId;
                    if (typeof $route.current.params.customerId === 'number')
                        return $route.current.params.customerId.toString();
                    return undefined;
                })();

                return customerId;
            }
        })();

        $scope.selectPreviousTransaction = () => $scope.select($scope.transactionIndex - 1);
        $scope.selectNextTransaction = () => $scope.select($scope.transactionIndex + 1);

        $scope.select = (index: number) => {
            const newIndex = parseInt(index.toString(), 10);
            const transactionsList = $scope.customer?.transactions ?? [];
            const transaction = transactionsList[newIndex];

            if (!transaction) {
                return;
            }

            $scope.transaction = transaction;
            $scope.transactionIndex = newIndex;
        };

        const loadPage = () => {
            if (!customerId) throw new Error('No customer ID provided.');
            const customerAPIPromise = CustomerAPI();

            const getConfig = (): Promise<IConfigCustomerDetailsProperties | null> =>
                ConfigAPI.get()
                    .then(api => Promise.all([api.user.get(), api.organization.get()]))
                    .then(([user, org]) => {
                        const userProperties: IConfigCustomerDetailsProperties | null = (() => {
                            const parsedOrgConfigProperties = ConfigCustomerDetailsPropertiesSchema.safeParse(
                                _.get(user, 'views.customersDetails.properties'),
                            );
                            if (parsedOrgConfigProperties.success) return parsedOrgConfigProperties.data;
                            return null;
                        })();
                        const organizationProperties: IConfigCustomerDetailsProperties | null = (() => {
                            const parsedOrgConfigProperties = ConfigCustomerDetailsPropertiesSchema.safeParse(
                                org.views?.customersDetails?.properties,
                            );
                            if (parsedOrgConfigProperties.success) return parsedOrgConfigProperties.data;
                            return null;
                        })();

                        return userProperties ?? organizationProperties ?? null;
                    });

            return $q
                .all([customerAPIPromise, Hierarchy.fetch(), getConfig()])
                .then(([api, hierarchy, configProperties]) => {
                    const { items } = hierarchy.segments;
                    const result = items.filter(f =>
                        Boolean(f.column && ['id', 'name', 'company', 'type', 'pattern'].includes(f.column)),
                    );
                    $scope.itemProperties = result.slice(0, 4);

                    const propertiesByField = _.keyBy(items, 'id');

                    if (configProperties) {
                        for (const [key, properties] of Object.entries(configProperties)) {
                            if (Array.isArray(properties) && (key === 'add' || key === 'del')) {
                                configProperties[key] = properties.filter(p => propertiesByField[p.field]);
                            }
                        }
                    }

                    return api.getDetails(customerId, configProperties).then(customerDetails => {
                        const { customer, customerExtraProperties } = customerDetails ?? {};
                        $scope.customer = customer;
                        $scope.customerExtraProperties = customerExtraProperties ?? [];
                        if (!$scope.customer) return;
                        $scope.select(0);
                    });
                });
        };

        $scope.customerDetailsPromiseTracker.addPromise(loadPage());
    },
];
